import React, { useState, useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import axios from 'axios';

import Backend from '../../utils/Backend';
import { renderPage } from '../../utils/renderer';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';

import styles from './styles.module.scss';

const postWidth = 190; //pixels

export default function SlugPage() {
  const [page, setPage] = useState<any>();
  const [postsCount, setPostsCount] = useState<number>(5);

  const router = useRouter();

  // Fetching Page
  useEffect(() => {
    if (router.query.slug) {
      let pathname: string;
      
      if (typeof router.query.slug === "string") pathname = router.query.slug;
      else pathname = router.query.slug.toString();

      const { promise, cancel } = Backend.getPageByURL(pathname)
      
      promise.then(({ data }) => {
        const page = data.content;

        if (Object.keys(page).length > 0) {
          setPage(page);
        }
        else {
          router.push('/404');
        }
      })
      .catch((err) => { 
        if (!axios.isCancel(err)) {
          router.push('/404'); 
        }
      });
    
      return cancel;
    }
  }, [router]);

  //Adapting posts count
  useEffect(() => {
    const adaptPage = () => {
      const windowWidth = window.innerWidth + (window.innerWidth / 10);
      
      let count = postsCount;
      
      count = Math.floor(windowWidth / (postWidth + (postWidth / 2))) + 1;
      
      if (count > postsCount) setPostsCount(count);
    }

    adaptPage();

    window.addEventListener('resize', adaptPage);
    return () => window.removeEventListener('resize', adaptPage);
  }, [postsCount]);


  return (
    <>
      { page && (
        <Head>
          <title>{ page.name } | Nevo</title>
          <meta name="description" content={page.description} />
        </Head>
      )}

      <Header />
      
      <div className={`${styles.slugPage} ${!page ? styles.loadingHolder : ""} page-holder`}>
        { page ? (
          <div>
            { page && renderPage(page, postsCount) }
          </div>
        ) : <Loader /> }
      </div>
      
      <Footer />
    </>
    
  )
}